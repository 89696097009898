import React, { CSSProperties, useEffect, useState } from 'react'
import { useAuth } from '../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'

export const Login = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const auth = useAuth()
  const navigate = useNavigate()

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    try {
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ clientName: username, password: password }),
      })

      const data = await response.json()

      if (response.ok) {
        auth?.login(data.token)
        navigate(`/${username}`)
      } else {
        alert('Login failed: ' + data.error)
      }
    } catch (error) {
      alert('An error occurred while logging in.')
    }
  }

  const containerStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f0f0f0',
  }

  const formStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '300px',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#ffffff',
  }

  const inputStyle = {
    marginBottom: '15px',
    padding: '10px',
    width: '100%',
    borderRadius: '4px',
    border: '1px solid #ccc',
    fontSize: '14px',
  }

  const buttonStyle: CSSProperties = {
    padding: '10px',
    width: '100%',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#0C48E5',
    color: 'white',
    cursor: 'pointer',
    fontSize: '16px',
  }

  const titleStyle: CSSProperties = {
    marginBottom: '20px',
    fontSize: '24px',
    color: '#333',
  }

  return (
    <div style={containerStyle}>
      <form onSubmit={handleSubmit} style={formStyle}>
        <h2 style={titleStyle}>Login</h2>
        <input
          type='text'
          value={username}
          onChange={e => setUsername(e.target.value)}
          placeholder='Username'
          style={inputStyle}
        />
        <input
          type='password'
          value={password}
          onChange={e => setPassword(e.target.value)}
          placeholder='Password'
          style={inputStyle}
        />
        <button type='submit' style={buttonStyle}>
          Login
        </button>
      </form>
    </div>
  )
}
