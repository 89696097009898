import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react'
import { jwtDecode } from 'jwt-decode'

interface AuthContextType {
  token: string | null
  login: (token: string) => void
  logout: () => void
  refreshAuth: () => boolean
  getClientName: () => string | null
  getIsAdmin: () => boolean
  getCurrentToken: () => string
}

const AuthContext = createContext<AuthContextType | null>(null)

interface AuthProviderProps {
  children: ReactNode
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [token, setToken] = useState<string | null>(null)

  useEffect(() => {
    const storedToken = localStorage.getItem('authToken')
    if (storedToken) {
      setToken(storedToken)
    }
  }, [])

  const login = (newToken: string) => {
    localStorage.setItem('authToken', newToken)
    setToken(newToken)
  }

  const logout = () => {
    localStorage.removeItem('authToken')
    setToken(null)
  }

  const refreshAuth = () => {
    const storedToken = localStorage.getItem('authToken')
    if (storedToken) {
      setToken(storedToken)
    }
    return !!storedToken
  }

  const getClientName = (): string | null => {
    if (!token) return null
    const decoded: any = jwtDecode(token)
    return decoded.clientName
  }

  const getIsAdmin = (): boolean => {
    if (!token) return false
    const decoded: any = jwtDecode(token)
    return decoded.clientName === 'admin'
  }

  const getCurrentToken = (): string => {
    return token || localStorage.getItem('authToken') || ''
  }

  return (
    <AuthContext.Provider
      value={{
        token,
        login,
        logout,
        refreshAuth,
        getClientName,
        getIsAdmin,
        getCurrentToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext)
  if (context === null) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
