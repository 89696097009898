import React, { useEffect, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { DateRangeDatePickerModes } from './Admin'
import { LayerProvider } from '@layerfi/components'
import { useSidebarContext } from '../contexts/SidebarContext'
import { Sidebar } from '../components/Sidebar'
import { FourOhFour } from './404'
import { useAuth } from '../contexts/AuthContext'
import { EnvironmentName, useEnvironment } from './Environment'

export const BusinessId = () => {
  const { businessId } = useParams()
  const { isSidebarOpen } = useSidebarContext()
  const { environment } = useEnvironment()

  const [loading, setLoading] = useState(true)
  const [importingTransactions, setImportingTransactions] = useState(false)
  const { getIsAdmin, getCurrentToken } = useAuth()

  const [isMobileView, setIsMobileView] = useState(false)
  const [showTooltips, setShowTooltips] = useState(false)
  const [isBookkeepingMode, setBookkeepingMode] = useState(false)
  const [showDescriptions, setShowDescriptions] = useState(true)
  const [showReceiptUploads, setShowReceiptUploads] = useState(true)
  const [showInvoicesTab, setShowInvoicesTab] = useState(false)
  const [datePickerMode, setDatePickerMode] =
    useState<DateRangeDatePickerModes | null>(null)
  const [useComparisonPnl, setUseComparisonPnl] = useState(false)
  const [showProjectView, setShowProjectView] = useState(false)

  const [clientId, setClientId] = useState('')
  const [clientSecret, setClientSecret] = useState('')
  useEffect(() => {
    const fetchCredentials = async () => {
      try {
        const response = await fetch('/api/clientCredentials', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: getCurrentToken(),
          },
          body: JSON.stringify({
            environment: environment,
          }),
        })

        const data = await response.json()

        if (response.ok) {
          setClientId(data.clientId)
          setClientSecret(data.clientSecret)
          setLoading(false)
        } else {
          console.error('Failed to fetch credentials:', data.error)
          setLoading(false)
        }
      } catch (error) {
        console.error('An error occurred while fetching credentials:', error)
        setLoading(false)
      }
    }

    if (environment) {
      fetchCredentials()
    }
  }, [environment])

  if (!getIsAdmin()) {
    return <FourOhFour />
  }

  return (
    <LayerProvider
      businessId={businessId ?? ''}
      environment={environment}
      appId={clientId}
      appSecret={clientSecret}
      theme={{
        colors: {
          light: { hex: '#EEEEEE' },
          dark: { hex: '#404040' },
          text: { hex: '#000000' },
        },
      }}
      usePlaidSandbox={false}
    >
      <div
        className={`structure sidebar--${isSidebarOpen ? 'open' : 'closed'}`}
      >
        <Sidebar
          brandLogoUrl={
            'https://cdn.prod.website-files.com/6630b7ef13e0914488bafe32/663e0540a23f2e604ebcb1c9_Logo.svg'
          }
          demoName={'Impersonate'}
          setImportingTransactions={setImportingTransactions}
          isMobileView={isMobileView}
          setIsMobileView={setIsMobileView}
          isBookkeepingMode={isBookkeepingMode}
          setBookkeepingMode={setBookkeepingMode}
          showInvoicesTab={showInvoicesTab}
          showTooltips={showTooltips}
          setShowTooltips={setShowTooltips}
        />
        <section className='main-content'>
          <Outlet
            context={{
              bearerToken: '',
              businessId: businessId,
              datePickerMode: datePickerMode,
              isMobileView: isMobileView,
              demoName: 'Impersonate',
              isBookkeepingMode: isBookkeepingMode,
              showDescriptions: showDescriptions,
              showReceiptUploads: showReceiptUploads,
              showInvoicesTab: showInvoicesTab,
              useComparisonPnl: useComparisonPnl,
              showTooltips: showTooltips,
              showProjectView: showProjectView,
            }}
          />
        </section>
      </div>
    </LayerProvider>
  )
}
