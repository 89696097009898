import {
  createCustomTransactions,
  CustomAccount,
  getExpenseCustomTransactions,
  validateCustomTransactions,
} from './importDummyDataHelpers'
import { ImportForm } from './ImportForm'
import React from 'react'

export interface ImportBankTransactionsProps {
  demoName: string
  businessId: string
  bearerToken: string
  defaultCustomAccount: CustomAccount
}

export const ImportBankTransactions = ({
  demoName,
  businessId,
  bearerToken,
  defaultCustomAccount,
}: ImportBankTransactionsProps) => {
  return (
    <ImportForm
      title='Bank Transactions'
      defaultCustomAccount={defaultCustomAccount}
      getInitialData={() => getExpenseCustomTransactions(demoName)}
      validateData={validateCustomTransactions}
      onSubmit={async transactions => {
        await createCustomTransactions(
          businessId,
          bearerToken,
          defaultCustomAccount.id,
          transactions,
        )
      }}
      dependencies={[demoName]}
    />
  )
}
