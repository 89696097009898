import React, { useEffect, useState } from 'react'
import { textAreaStyle } from './ImportDummyData'
import { CustomAccount } from './importDummyDataHelpers'

interface ImportFormProps {
  title: string
  defaultCustomAccount: CustomAccount
  getInitialData: () => any
  validateData: (data: any) => boolean
  onSubmit: (data: any) => Promise<void>
  dependencies?: any[]
}

export const ImportForm = ({
  title,
  defaultCustomAccount,
  getInitialData,
  validateData,
  onSubmit,
  dependencies = [],
}: ImportFormProps) => {
  const [payload, setPayload] = useState<string>('')

  useEffect(() => {
    if (defaultCustomAccount != null) {
      const initialData = getInitialData()
      setPayload(JSON.stringify(initialData, null, 2))
    }
  }, [defaultCustomAccount, getInitialData, ...dependencies])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    try {
      const parsedPayload = JSON.parse(payload)
      if (!validateData(parsedPayload)) {
        throw new Error(`Invalid ${title.toLowerCase()} payload structure`)
      }

      await onSubmit(parsedPayload)
      alert(`${title} imported successfully!`)
    } catch (error) {
      console.error('Error in payload or submission:', error)
      alert(`Failed to submit: ${error}`)
    }
  }

  return (
    <fieldset
      style={{
        minWidth: '400px',
        maxWidth: '600px',
        padding: '20px',
        border: '2px solid black',
        borderRadius: '5px',
        flex: '1',
      }}
    >
      <legend style={{ fontSize: '1.2em', padding: '0 10px' }}>
        Import {title}
      </legend>
      <form onSubmit={handleSubmit}>
        <textarea
          style={textAreaStyle}
          value={payload}
          onChange={e => setPayload(e.target.value)}
        />
        <div style={{ textAlign: 'right' }}>
          <br />
          <button className='admin-button' type='submit'>
            Submit {title}
          </button>
        </div>
      </form>
    </fieldset>
  )
}
