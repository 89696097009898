import {
  getInvoices,
  validateInvoices,
  createMatchingCustomTransactionsFromInvoices,
  createCustomTransactions,
  CustomAccount,
} from './importDummyDataHelpers'
import { ImportForm } from './ImportForm'
import React from 'react'
import { LAYER_API_URL } from '../../Root'

export interface ImportInvoicesProps {
  demoName: string
  businessId: string
  bearerToken: string
  defaultCustomAccount: CustomAccount
}

export const ImportInvoices = ({
  demoName,
  businessId,
  bearerToken,
  defaultCustomAccount,
}: ImportInvoicesProps) => {
  return (
    <ImportForm
      title='Invoices'
      defaultCustomAccount={defaultCustomAccount}
      getInitialData={() => getInvoices(demoName)}
      validateData={validateInvoices}
      onSubmit={async invoices => {
        const invoiceResponse = await fetch(
          `${LAYER_API_URL}/v1/businesses/${businessId}/invoices/bulk`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${bearerToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(invoices),
          },
        )
        if (!invoiceResponse.ok) {
          throw new Error('Invoice API call failed')
        }

        const matchingTxns = await createMatchingCustomTransactionsFromInvoices(
          invoices,
          demoName,
        )
        await createCustomTransactions(
          businessId,
          bearerToken,
          defaultCustomAccount.id,
          matchingTxns,
        )
      }}
      dependencies={[demoName]}
    />
  )
}
