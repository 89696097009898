import React from 'react'
import { Outlet, useOutletContext, useParams } from 'react-router-dom'
import { FourOhFour } from './404'

export enum EnvironmentName {
  Staging = 'staging',
  Sandbox = 'sandbox',
  Production = 'production',
}

const isEnvironmentName = (value: string): value is EnvironmentName => {
  return Object.values(EnvironmentName).includes(value as EnvironmentName)
}

type EnvironmentContextType = {
  environment: EnvironmentName
}

export const useEnvironment = () => {
  return useOutletContext<EnvironmentContextType>()
}

export const Environment = () => {
  const { environment } = useParams<{ environment: string }>()

  if (!environment || !isEnvironmentName(environment)) {
    return <FourOhFour />
  }

  return <Outlet context={{ environment }} />
}
